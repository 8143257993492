export default {
  "thank-you": {
    "heading": "Congratulations!",
    "body": [
      "You’ve just taken the first step to resolve your debt. One of our debt specialists will contact you shortly to complete your FREE financial analysis and answer any questions you may have." + " " +
      "You can get a head start by calling our toll-free number below:",
      "<a href='tel:888-382-5452'>888-382-5452</a>"
    ],
    "anchor": true
  },
  "timeout-warning": {
    "heading": "Are you still there?",
    "body": [
      "Please note: your session will expire in two minutes.",
      "<button data-handler='click:clearTimeouts' type='button' class='button'>OK</button>"
    ]
  },
  "timeout-notice": {
    "heading": "Your session has expired.",
    "body": [
      "Please <a href='/?timeout_refresh_click'>refresh your browser</a> to restart the inquiry process."
    ],
    "modifiers": [
      "modal"
    ]
  }
};
