<template>
  <div ref="container" id="lead-form-container" class="site-section lead-form ">
    <h2 class="section-header section-header--lead-form">{{ heading }}</h2>
    <div
      :class="[
        'lead-form__app',
        'js-lead-form-container',
        digitalOnly ? 'digital-lead' : ''
      ]"
    >
      <form
        id="lead-form"
        ref="form"
        action="submit"
        :class="[
          'lead-form__grid',
          'js-lead-form',
          submitted ? 'submitted' : ''
        ]"
      >
        <div class="lead-form__error-message js-form-error-message">{{ errorMessage }}</div>
        <template v-if="!submitted">
          <template v-for="element in elements">
            <BaseInput
              v-if="element.type === 'hidden'"
              ref="inputs"
              :key="element.machine_names.form"
              type="hidden"
              v-bind="element"
            />
            <BaseInputGroup
              v-else
              :key="element.machine_names.form"
              v-bind="element"
            >
              <BaseSelect v-if="element.type === 'select'" ref="inputs" v-bind="element" v-on="element.listeners"/>
              <template v-else>
                <template v-if="element.machine_names.form === 'client-id'">
                  <BaseLabel :target="element.machine_names.form" :text="element.label"/>
                  <BaseInput ref="inputs" v-bind="element" v-on="element.listeners"/>
                </template>
                <template v-else>
                  <BaseInput ref="inputs" v-bind="element" v-on="element.listeners"/>
                  <BaseLabel v-if="element.type !== 'submit'" adaptive :target="element.machine_names.form" :text="element.label"/>
                </template>
              </template>
            </BaseInputGroup>
          </template>
        </template>
        <template v-for="{ name, ...message } in renderedMessages">
          <div
            v-if="message.modifiers && message.modifiers.includes('modal')"
            :key="name"
            :class="[
              'lead-form__message-modal-wrapper',
              `lead-form__message-modal-wrapper--${name}`,
            ]"
          >
            <div v-if="message.anchor" ref="messageAnchor" :key="`${name}-anchor`" :class="['anchor', `anchor--${name}`]"></div>
            <BaseMessage ref="messages" :key="name" :name="name" v-bind="message"/>
          </div>
          <template v-else>
            <div v-if="message.anchor" ref="messageAnchor" :key="`${name}-anchor`" :class="['anchor', `anchor--${name}`, 'js-anchor', `js-anchor--${name}`]"></div>
            <BaseMessage ref="messages" :key="name" :name="name" v-bind="message"/>
          </template>
          <img v-if="pixels[name]" :key="name" :src="pixels[name]" height="0" width="0" border="0">
        </template>
      </form>
    </div>
  </div>
</template>

<script>
/* global Vue */
/* eslint no-console: 0 */
import axios from 'axios';
import { get, mapValues, set } from 'lodash';
import cookies from 'vue-cookies';
import { elements, messages} from './data';
import * as analytics from './internals/analytics';
import * as qs from './internals/qs';
import * as timeout from './internals/timeout';
import { API_BASE_URL } from './config';
import BaseInput from './components/BaseInput.vue';
import BaseInputGroup from './components/BaseInputGroup.vue';
import BaseLabel from './components/BaseLabel.vue';
import BaseMessage from './components/BaseMessage.vue';
import BaseSelect from './components/BaseSelect.vue';

Vue.use(cookies);

const internals = {
  ...qs,
  ...timeout,
};

/**
 * serializeForm
 *
 * @param {object} form - the DOM object of the targeted form
 * @return {object} - a newly created object, with key/value pairs corresponding
 * to the names/values of the form's inputs.
 *
 * @example
 *
 *     const data = serializeForm(formObj)
 */
const serializeForm = function(form) {
  const formData = {};
  Array.prototype.forEach.call(form.elements, function(el) {
    formData[el.name] = el.value;
  });

  return formData;
};

/**
 * sanitizeData
 *
 * @param {object} formData - the form data coming from the form
 * @return {object} - the same object, cleaned up in regards to characters in phone
 * numbers and other numeric or boolean inputs.
 *
 * @example
 *
 *     const sanitizedData = sanitizeData(formData)
 */
const sanitizeData = function(formData) {
  const re = new RegExp(/\D/g);
  formData.phone = formData.phone.replace(re, '');
  formData.estimatedDebt = formData.estimatedDebt.replace(re, '');

  return formData;
};

const utils = {
  errors: {
    whitelist: [
      /^Please enter a valid [\w\s]+ amount/
    ]
  },
  /**
   * utils.isInvisible
   *
   * @param {element} element - the DOM element whose invisibility we want to check on
   * @return {boolean} - true/false, depending on whether the element is visible
   *
   * @example
   *
   *     utils.isInvisible(document.getElementById('myFormInput'))
   */
  isInvisible: (element) => !(element.offsetWidth > 0 && element.offsetHeight > 0),
  isSuccessfulResponse: (status, data) => status >= 200 && status < 400 && !data.error,
  whichError: (data, xhr) => {
    if (
      data.message
      &&
      utils.errors.whitelist
        .reduce((prev, test) => prev || Boolean(data.message.match(test)), false)
    ) {
      return data.message;
    }
    return data.status || xhr.status;
  },
  fixInvalidPlaceholders: ({ target: el }) => { el.value.length > 0 ? el.classList.add('active') : el.classList.remove('active') }
};

export default {
  name: 'LeadForm',
  components: {
    BaseInput,
    BaseInputGroup,
    BaseLabel,
    BaseMessage,
    BaseSelect
  },
  props: {
    brand: {
      type: Object,
      default: () => ({})
    },
    continue: String,
    digitalOnly: {
      type: Boolean,
      default: false
    },
    heading: String,
    pixels: {
      type: Object,
      default: () => ({})
    },
    prefix: String,
    presets: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    elements,
    messages,
    attachableListeners: {},
    errorMessage: '',
    lead: null,
    listeners: {},
    renderedMessages: [],
    submitted: false,
    timeout: false,
    timeoutWarning: false,
    tokens: null
  }),
  created() {
    internals.vm = this;

    const presets = { ...this.presets };
    internals.getURLParams(presets);
    analytics.init(this);

    const prefix = this.elements.find(({ machine_names: { form: id } }) => id === 'prefix');
    const replacement = this.prefix.toUpperCase();
    set(prefix, ['attributes', 'value'], replacement);

    if (!this.digitalOnly) {
      const prc = this.elements.find(({ machine_names: { form: id } }) => id === 'client-id');
      const replace = [ 'placeholder', 'data-alt-placeholder', 'title', 'data-alt-title' ];
      const regexReplace = [ 'pattern', 'data-alt-pattern' ];
      const regexReplacement =
        `[${this.prefix[0].toUpperCase()}${this.prefix[0].toLowerCase()}][${this.prefix[1].toUpperCase()}${this.prefix[1].toLowerCase()}]`;

      replace.forEach(attribute => {
        const path = ['attributes', attribute];
        set(prc, path, get(prc, path, '').replace('--', replacement));
      });

      regexReplace.forEach(attribute => {
        const path = ['attributes', attribute];
        set(prc, path, get(prc, path, '').replace('--', regexReplacement));
      });
    }

    this.register('focusout', utils.fixInvalidPlaceholders);
  },
  beforeMount() {
    this.attachableListeners = mapValues(
      this.listeners,
      funcs =>
        e => {
          funcs.forEach(func => { func(e) });
        }
    );

    this.elements = [
      ...internals.captureParams().reduce((prev, { name, value }) => {
        return [ ...prev, {
          machine_names: {
            api: name,
            form: name,
          },
          type: 'hidden',
          name,
          attributes: {
            value
          }
        }];
      }, []),
      ...this.elements.map(element => {
        get(element, ['display_conditions'], []).forEach(condition => {
          const [ [ param, value ] ] = Object.entries(condition.value);
          if (
            (condition.verb === 'hide' && this[param] === value)
            ||
            (condition.verb === 'show' && this[param] !== value)
          ) {
            element.type = 'hidden';
          } else if (
            (condition.verb === 'remove' && this[param] === value)
            ||
            (condition.verb === 'leave' && this[param] !== value)
          ) {
            element = null;
          }
        });

        return element;
      }).filter(element => element != null)
    ].map(element => {
      if (element.type === 'hidden') {
        return {
          ...element,
          attributes: {
            ...element.attributes,
            autocomplete: 'doNotFill'
          }
        };
      }

      const finishedElement = {
        ...element,
        listeners: {
          ...this.attachableListeners
        }
      };

      if (element.type === 'submit') {
        finishedElement.attributes.value = this._props.continue || finishedElement.attributes.value;
        finishedElement.listeners.click = this.submitForm.bind(this);
      }

      return finishedElement;
    });
  },
  mounted() {
    this.getTokens();
  },
  updated() {
    const { messages = [] } = this.$refs;
    messages.forEach(message => {
      const emitters = [ ...message.$el.querySelectorAll('[data-handler]') ];
      emitters.forEach(emitter => {
        const [ eventName, handler ] = emitter.dataset.handler.split(':');
        emitter.addEventListener(eventName, () => { internals[handler](); });
      });
    });

    const [ messageAnchor ] = get(this, ['$refs', 'messageAnchor'], []);
    if (messageAnchor) {
      messageAnchor.scrollIntoView();
    }
  },
  methods: {
    getInvalidFields() {
      return this.$refs.inputs.filter(input => !input.isValid()).map(({ $el }) => $el);
    },
    getUntouchedFields() {
      return this.$refs.inputs.filter(input => !input.touched).map(({ $el }) => $el);
    },
    clearForm() {
      const { form } = this.$refs;
      form.reset();
    },
    register(eventName, listener) {
      const path = ['listeners', eventName];
      const eventListeners = get(this, path, []);
      eventListeners.push(listener);
      set(this, path, eventListeners);
    },
    unregister(eventName, listener) {
      const path = ['listeners', eventName];
      const eventListeners = get(this, path);
      if (eventListeners) {
        eventListeners.splice(this.listeners.indexOf(listener), 1);
      }
    },
    getTokens() {
      axios.get(`${API_BASE_URL}/get-tokens?prefix=${this.prefix.toUpperCase()}`)
        .then(({ data: { data: tokens = {} } }) => {
          this.tokens = tokens;
          this.clearResponseError();
          internals.initTimeouts(internals.querystring.asObject.timeout, internals.querystring.asObject.warning);
        })
        .catch((args) => {
          console.log('args', args);
          this.showResponseError(403);
        });
    },
    submitForm(e) {
      if (!this.submitting) {
        this.submitting = true;
        this.disableForm();

        const { form, container, inputs } = this.$refs;
        const [ submitButton ] = inputs.filter(input => input.type === 'submit').map(({ $el }) => $el);

        //Log Pre-submission Form input
        const rawFormData = serializeForm(form);
        const formData = sanitizeData(rawFormData);

        /* Only allow a submission if the form data passes validation.
        * Note that the default function is prevented here and not earlier to allow
        * for our custom submit function while preserving the HTML5 standard
        * input validations and error messages to be displayed to the user after
        * the unvalidated input is logged above.
        */

        if (form.checkValidity()) {
          e.preventDefault();
          axios.post(`${API_BASE_URL}/lead-form-email`, {
            token: this.tokens.access_token,
            form_data: formData
          })
            .then(({ data, status }) => {
              this.submitting = false;

              if (utils.isSuccessfulResponse(status, data)) {
                // handle successful lead form submission
                analytics.formSubmitted();
                container.classList.add('submitted');
                this.clearResponseError();
                this.renderedMessages = [ { name: 'thank-you', ...this.messages['thank-you'] } ];
                submitButton.disabled = false;
                this.submitted = true;
              } else {
                return Promise.reject({ response: { data, status } });
              }
            })
            .catch(({ response: { data, status } }) => {
              analytics.formFailed(this.getInvalidFields());
              this.showResponseError(utils.whichError(data, { status }));
              this.enableForm();
            })
            .finally(() => {
              this.submitting = false;
            });
        } else {
          analytics.formFailed(this.getInvalidFields());
          this.submitting = false;
          this.enableForm();
        }
      }
    },
    disableForm(lookup) {
      const { inputs } = this.$refs;
      const [ submitButton ] = inputs.filter(input => input.type === 'submit').map(({ $el }) => $el);
      const submitValue = this._props.continue || submitButton.value;

      submitButton.disabled = true;
      if (!lookup) {
        submitButton.value = submitButton.dataset.loadingContent || submitValue;
      }
    },
    enableForm() {
      const { inputs } = this.$refs;
      const [ submitButton ] = inputs.filter(input => input.type === 'submit').map(({ $el }) => $el);
      const submitValue = this._props.continue || submitButton.dataset.defaultContent;

      submitButton.disabled = false;
      submitButton.value = submitValue || submitButton.value;
    },
    showResponseError(code) {
      let errorMessage = '';

      if (typeof code === 'string') {
        errorMessage = code;
      } else {
        switch (code) {
          case 400:
            // The code submitted wasn't formatted correctly. look at the result param to see what the issue was
            errorMessage = 'Your inquiry was not processed. Please check the information entered and try again.';
            break;
          case 404:
          case 410:
          case 600:
            errorMessage = 'Please ensure all required fields are entered.';
            break;
          case 601:
          case 0:
          case 401:
          case 403:
          case 500:
          default:
            errorMessage = this.brand ? `We’re sorry but we cannot currently take inquiries online. Please call ${this.brand.phone} so that one of our representatives can help you. Thank you.` : 'We\'re sorry, there seems to be an issue contacting our data center.';
        }
      }

      this.errorMessage = errorMessage;
    },
    clearResponseError() {
      this.errorMessage = '';
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
