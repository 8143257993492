export default [
  {
    "machine_names": {
      "api": "prefix",
      "form": "prefix"
    },
    "type": "hidden"
  },
  {
    "machine_names": {
      "api": "firstName",
      "form": "first-name"
    },
    "label": "First Name",
    "attributes": {
      "required": true,
      "title": "Enter first name"
    }
  },
  {
    "machine_names": {
      "api": "lastName",
      "form": "last-name"
    },
    "label": "Last Name",
    "attributes": {
      "required": true
    }
  },
  {
    "machine_names": {
      "api": "email",
      "form": "email-address"
    },
    "label": "E-Mail Address",
    "type": "email",
    "attributes": {
      "required": true,
      "title": "ex: example@emailserver.com"
    }
  },
  {
    "machine_names": {
      "api": "phone",
      "form": "phone-number"
    },
    "label": "Phone Number",
    "classes": [ "js-phone-input" ],
    "type": "tel",
    "attributes": {
      "pattern": "^\\(?[2-9]{1}[0-9]{2}\\)?[ -]?[2-9]{1}[0-9]{2}[ -]?[0-9]{4}$",
      "required": true,
      "title": "10 digits, may not begin with '0' or '1'"
    }
  },
  {
    "machine_names": {
      "api": "estimatedDebt",
      "form": "credit-debt"
    },
    "type": "tel",
    "prepend": true,
    "label": "Estimated credit card debt",
    "attributes": {
      "pattern": "^\\s*(?:[1-9]\\d{0,2}(?:,\\d{3})+|[1-9]\\d{3})\\s*$",
      "title": "Enter an amount of $1,000 or more",
      "required": true,
      "maxlength": 15,
      "autocomplete": "doNotFill"
    },
    "classes": [ "js-currency-input" ],
    "isCurrency": true
  },
  {
    "machine_names": {
      "form": "submit"
    },
    "type": "submit",
    "attributes": {
      "value": "Continue",
      "data-loading-content": "Submitting...",
      "data-default-content": "Continue"
    }
  }
];
