<template>
  <label v-bind:for="target" class="lead-form__label" :placeholder="adaptive && text" v-html="!adaptive ? text : ''"/>
</template>

<script>
export default {
  name: 'BaseLabel',
  props: {
    adaptive: Boolean,
    target: String,
    text: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
