export function displayConditions() {
  return (
    this.$attrs.display_conditions &&
    this.$attrs.display_conditions.map(
      ({ verb, value }) =>
        `${verb}-when-${Object.entries(value)[0].join("-")}`
    )
  );
}

export function id() {
  return this.$attrs.machine_names.form;
}

/* 
 * The below is taken from the following section in the 
 * Vue.js 2 docs:
 * https://vuejs.org/v2/guide/components-custom-events.html#Binding-Native-Events-to-Components
 */
export function listeners() {
  var vm = this;
  // `Object.assign` merges objects together to form a new object
  return {
    // We add all the listeners from the parent
    ...this.$listeners,
    // Then we can add custom listeners or override the
    // behavior of some listeners.
    focus(...args) {
      if (vm.$listeners.focus) {
        vm.$listeners.focus(...args);
      }
      vm.touched = true;
    },
    // This ensures that the component works with v-model
    input(event) {
      vm.$emit('input', event.target.value)
    }    
  };
}

export function name() {
  return this.$attrs.machine_names.api;
}

export default {
  computed: {
    displayConditions,
    id,
    listeners,
    name
  },
  data: () => ({
    touched: false
  }),
  methods: {
    isValid() {
      return this.$el.checkValidity();
    }
  }
};
