import { DEFAULT_TIMEOUT, DEFAULT_TIMEOUT_WARNING } from '../config';

export const timers = {};

/**
 * initTimeouts
 *
 * Return-less function, which wires up the running timeout counters
 * both to the warning and the timeout itself. (Taken from previous WP lead form,
 * and largely unedited.)
 *
 * @example
 *
 *     initTimeouts(300000)
 */
export function initTimeouts(timeout = DEFAULT_TIMEOUT, warning = DEFAULT_TIMEOUT_WARNING) {
  this.timers.warningTimer = setTimeout(() => {
    this.displayTimeoutWarning();
  }, warning);

  this.timers.timeoutTimer = setTimeout(() => {
    this.triggerTimeout();
  }, timeout);
}

/**
 * @description : unhides the timeout warning
 * @return {none} none
 */
export function displayTimeoutWarning() {
  const { form } = this.vm.$refs;
  this.vm.timeoutWarning = true;
  form.dataset.timeoutWarning = true;
  this.vm.renderedMessages = [ { name: 'timeout-warning', ...this.vm.messages['timeout-warning'] } ];
}

/**
 * @description : clears the setTimeout'd vars
 * @return {boolean} false - to preventDefault
 */
export function clearTimeouts() {
  const { form } = this.vm.$refs;
  clearTimeout(this.timers.warningTimer);
  clearTimeout(this.timers.timeoutTimer);
  this.vm.timeoutWarning = null;
  form.dataset.timeoutWarning = null;
  this.vm.renderedMessages = [];
  this.vm.getTokens();
  return false;
}

/**
 * @description : expires the form (the user has taken > 15 min)
 */
export function triggerTimeout() {
  const { form } = this.vm.$refs;
  this.vm.timeoutWarning = null;
  form.dataset.timeoutWarning = null;
  this.vm.timeout = true;
  form.dataset.timeout = true;
  this.vm.renderedMessages = [ { name: 'timeout', ...this.vm.messages['timeout-notice'] } ];
  this.vm.clearForm();
}
