
export const querystring = {};
export const paramsToCapture = [
  'ls',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term'
];

export function captureParams() {
  return paramsToCapture.reduce((prev, param) => {
    const fromCookie = this.vm.$cookies.get(param);
    const fromQs = querystring.asObject[param];

    if (fromQs && fromQs != fromCookie) {
      this.vm.$cookies.set(param, fromQs, 0);
    }

    const value = fromQs || fromCookie;

    if (value) {
      return [
        ...prev,
        { name: param, value }
      ];
    }
    return prev;
  }, []);
}

export function getURLParams(presets) {
  querystring.asObject = window.location.search
    .slice(1)   // remove the '?'
    .split('&') // make an array of strings for each key-value pair
    .reduce((prev, keyValPair) => {
      if (keyValPair) {
        // split the 'key=value' string to an array,
        // then further parse it into the key and value
        // variables via destructuring assignment, with
        // a default value of true for the value
        const [ key, value = true ] = keyValPair.split('=');
        if (key) {
          return { ...prev, [key]: value };
        }
      }

      return prev;
    }, {});

  Object.assign(querystring.asObject, presets);
}
