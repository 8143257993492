import get from 'lodash/get';

let lastFocused;

/**
 * analytics.init
 *
 * Param-less, return-less function, to wire up the analytics start event,
 * and created the global vars needed for data storage. Original notes:
 * Sets up analytics events to be fired during the application process
 *
 * @example
 *
 *     analytics.init()
 */
export const init = vm => {
  window.dataLayer = window.dataLayer || [];
  vm.register('focus', e => {
    formStart(e);
    lastFocused = e.target.id;
  });
  window.addEventListener('beforeunload', () => {
    formAbandoned();
  });
};

/**
 * analytics.formStart
 *
 * Param-less, return-less function, event handler to deal with the user
 * beginning the form.
 *
 * @example
 *
 *     element.addEventListner(analytics.formStart)
 */
export const formStart = e => {
  if (
    !window.dataLayer.find(item => get(item, ['event']) === 'formInitiated' && get(item, ['form', 'name']) === 'Lead')
  ) {
    window.dataLayer.push({
      'form': {
        'name': 'Lead',
        'field': e && e.target.id
      },
      'event': 'formInitiated'
    });
  }
};

export const formAbandoned = () => {
  if (
    window.dataLayer.find(item =>
      get(item, ['event']) === 'formInitiated'
      &&
      get(item, ['form', 'name']) === 'Lead'
    )
    &&
    !window.dataLayer.find(item =>
      get(item, ['event']) === 'formSubmitted'
      &&
      get(item, ['form', 'name']) === 'Lead'
    )
  ) {
    window.dataLayer.push({
      'form': {
        'name': 'Lead',
        'field': lastFocused
      },
      'event': 'formAbandoned'
    });
  }
};

/**
 * analytics.formSubmitted
 *
 * Param-less, return-less function, function to mark a successful
 * lead form submission.
 *
 * @example
 *
 *     analytics.formSubmitted(clientId)
 */
export const formSubmitted = clientId => {
  window.dataLayer.push({
    'form': {
      'name': 'Lead',
      'prn': clientId
    },
    'event': 'formSubmitted'
  });
};

/**
 * analytics.formFailed
 *
 * Param-less, return-less function, function to mark a successful
 * lead form submission.
 *
 * @example
 *
 *     analytics.formFailed(clientId)
 */
export const formFailed = fields => {
  window.dataLayer.push({
    'form': {
      'name': 'Lead',
      'field': fields.map(({ id }) => id).join(', ')
    },
    'event': 'formFailed'
  });
};
