<template>
  <select
    :id="id"
    :name="name"
    :type="type"
    :class="[
      'lead-form__input',
      `lead-form__input--${id}`,
      ...classes,
      ...displayConditions
    ]"
    v-bind="attributes"
    v-on="listeners"
  >
    <option
      v-for="option in options"
      v-bind:key="option.attributes"
      :value="option.value"
    >{{ option.label }}</option>
  </select>
</template>

<script>
import inputMixin from '../mixins/input.js';

export default {
  name: "BaseSelect",
  mixins: [inputMixin],
  inheritAttrs: false,
  props: {
    attributes: Object,
    classes: Array,
    options: Array,
    type: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
