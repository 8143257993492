<template>
  <div
    v-bind:key="id"
    :class="[
      'lead-form__group',
      `lead-form__group--${id}`,
      prepend && 'lead-form__group--prepend',
      type === 'hidden' && 'lead-form__group--hidden',
      ...displayConditions
    ]"
  >
    <slot/>
  </div>
</template>

<script>
import { displayConditions, id } from '../mixins/input';

export default {
  name: 'BaseInputGroup',
  inheritAttrs: false,
  props: {
    prepend: Boolean,
    type: String
  },
  computed: {
    displayConditions,
    id
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
