<template>
  <div
    :class="[
      'lead-form__message',
      `lead-form__message--${name}`,
      'js-lead-form-message',
      `js-lead-form-message-${name}`,
      ...modifiers.reduce(
        (prev, modifer) =>
          [ ...prev, `lead-form__message--${modifier}`, `js-message-${modifier}` ],
        []
      )
    ]"
  >
    <h3>{{ heading }}</h3>

    <p v-for="(para, index) in body" :key="index" v-html="para"></p>
  </div>
</template>

<script>
export default {
  name: "BaseMessage",
  inheritAttrs: false,
  props: {
    body: Array,
    heading: String,
    modifiers: {
      type: Array,
      default: () => []
    },
    name: String
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
