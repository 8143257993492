var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'lead-form__message',
    ("lead-form__message--" + _vm.name),
    'js-lead-form-message',
    ("js-lead-form-message-" + _vm.name) ].concat( _vm.modifiers.reduce(
      function (prev, modifer) { return prev.concat( [("lead-form__message--" + _vm.modifier)], [("js-message-" + _vm.modifier)] ); },
      []
    )
  )},[_c('h3',[_vm._v(_vm._s(_vm.heading))]),_vm._l((_vm.body),function(para,index){return _c('p',{key:index,domProps:{"innerHTML":_vm._s(para)}})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }